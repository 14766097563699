.App {
  text-align: center;
}

.div-clickable {
  cursor: pointer;
}

.div-bg {
  background-image: url("../src/assets/Artboard\ 1_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.div-list-bg {
  background-image: url("../src/assets/Artboard\ 1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.div-list-qr {
  background-image: url("../src/assets/Asset\ 3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}